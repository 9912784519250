import React from 'react';
import './Form.css';

export default function Input({ name, label, type, placeholder, id, max, value, readonly, setValue, funcCall }) {
	return (
		<>
			{setValue && (
				<div className='mb-3'>
					<label className='frmLabel'>{label}</label>
					<input className='inputBox form-control' type={type} name={name} id={id} maxLength={max} readOnly={readonly} value={value} onChange={(e) => setValue(e.target.value)} />
				</div>
			)}
			{funcCall && label && (
				<div className='mb-3'>
					<label className='frmLabel'>{label}</label>
					<input className='inputBox form-control' type={type} name={name} id={id} maxLength={max} readOnly={readonly} value={value} onChange={(e) => funcCall(e)} />
				</div>
			)}
			{!setValue && !funcCall && (
				<div className='mb-3'>
					<label className='frmLabel'>{label}</label>
					<input className='inputBox form-control' type={type} name={name} id={id} maxLength={max} readOnly={readonly} value={value} />
				</div>
			)}
			{!label && (
				<input
					className='inputBox form-control'
					type={type}
					placeholder={placeholder}
					name={name}
					id={id}
					maxLength={max}
					readOnly={readonly}
					value={value}
					onChange={(e) => funcCall(e)}
				/>
			)}
		</>
	);
}
