import React from 'react';
import './spinner.css';

export default function Spinner() {
	return (
		<div className='spinnerParent'>
			<div className='spinner' />
		</div>
	);
}
