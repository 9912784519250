import React from 'react';
import './footer.css';

export default function Footer() {
	let yr = new Date().getFullYear();

	return (
		<footer className='footer mb-1 bg-transparent fixed-bottom ps-1' style={{ height: '20px' }}>
			{yr === 2023 && <span className='ftrText'>&copy;2023 Zaxxiss, LLC</span>}
			{yr >= 2024 && <span className='ftrText'>&copy;2023-{yr} Zaxxiss, LLC</span>}
		</footer>
	);
}
