import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Select from 'react-select';
import Checkbox from '../form/Checkbox';
import Input from '../form/Input';
import Button from '../form/Button';
import Spinner from '../spinner/Spinner';

const formatPrice = (value) => {
	if (!value) return value;
	let tmpPrice = value.replace(/[^\d]/g, '');
	const tmpPriceLength = tmpPrice.length;

	if (tmpPriceLength < 3) return tmpPrice;

	if (tmpPriceLength >= 3) {
		let convertPrice = tmpPrice.toString();
		let cents = convertPrice.slice(-2);
		let dollars = convertPrice.slice(0, -2);
		return `${dollars}.${cents}`;
	}
};

export default function AddDomain() {
	const { auth } = useContext(AuthContext);
	const [usersOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [name, setName] = useState('');
	const [exp, setExp] = useState('');
	const [hosted, setHosted] = useState(true);
	const [hostPrice, setHostPrice] = useState(null);
	const [renewPrice, setRenewPrice] = useState(null);
	const [emailPrice, setEmailPrice] = useState(null);
	const [loading, setLoading] = useState(false);

	const loadUsers = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getUsers/${auth.user.admin}`);
			if (data.length === 0) {
				toast.error('No users found');
				return;
			} else {
				if (usersOptions.length === 0) {
					data.forEach((user) => usersOptions.push({ label: `${user.fname} ${user.lname}`, value: user._id }));
				}
			}
		} catch (err) {
			toast.error(err);
		}
	}, [auth, usersOptions]);

	useEffect(() => {
		loadUsers();
	}, [loadUsers]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const convertedHostPrice = parseInt(hostPrice * 100);
			const convertedRenewPrice = parseInt(renewPrice * 100);
			const convertedEmailPrice = parseInt(emailPrice * 100);
			await axios.post(`/addDomain/${auth.user.admin}`, {
				name,
				expire: exp,
				hosted,
				hostingprice: convertedHostPrice,
				renewalprice: convertedRenewPrice,
				emailprice: convertedEmailPrice,
				userObjId: selectedUser.value,
			});
			setName('');
			setExp('');
			setHosted(false);
			setHostPrice('');
			setRenewPrice('');
			setEmailPrice('');
			setLoading(false);
			toast.success('Domain added successfully');
		} catch (error) {
			setLoading(false);
			toast.error(error);
		}
	};

	const handleHosted = (e) => {
		const value = e.target.checked;
		setHosted(value);
	};

	function handleHostPrice(e) {
		const value = e.target.value;
		const formattedPrice = formatPrice(value);
		setHostPrice(formattedPrice);
	}

	function handleRenewPrice(e) {
		const value = e.target.value;
		const formattedPrice = formatPrice(value);
		setRenewPrice(formattedPrice);
	}

	function handleEmailPrice(e) {
		const value = e.target.value;
		const formattedPrice = formatPrice(value);
		setEmailPrice(formattedPrice);
	}

	return (
		<div className='row mt-4'>
			<div className='col-10 col-md-4 offset-1 offset-md-4'>
				<div className='mb-3'>
					<Select
						isMulti={false}
						blurInputOnSelect={true}
						closeMenuOnSelect={true}
						options={usersOptions}
						onChange={setSelectedUser}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								backgroundColor: '#c9c9c9',
								border: '2px solid black',
								borderRadius: '7px',
							}),
						}}
					/>
				</div>
				<form onSubmit={handleSubmit}>
					<Input label='Domain Name' type='text' value={name} setValue={setName} />
					<Input label='Expires' type='text' value={exp} setValue={setExp} />
					<div className='row mb-3'>
						<div className='col-auto pe-2'>
							<Checkbox checked={hosted} funcCall={handleHosted} />
						</div>
						<div className='col ps-2'>
							<div className='frmLabel'>Hosted</div>
						</div>
					</div>
					<Input label='Hosting Price' type='text' value={hostPrice} funcCall={handleHostPrice} />
					<Input label='Renewal Price' type='text' value={renewPrice} funcCall={handleRenewPrice} />
					<Input label='Email Price' type='text' value={emailPrice} funcCall={handleEmailPrice} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' disabled={!name || !renewPrice}>
							Save Domain
						</Button>
					</div>
				</form>
			</div>
			{loading && <Spinner />}
		</div>
	);
}
