import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import LoadingRedirect from './LoadingRedirect';
import axios from 'axios';

export default function PrivateRoutes() {
	const { auth } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const authCheck = async () => {
			const { data } = await axios.get('/auth-check');
			if (!data.ok) {
				setLoading(true);
			} else {
				setLoading(false);
			}
		};
		if (auth) {
			authCheck();
		}
	}, [auth]);

	return loading ? <LoadingRedirect /> : <Outlet />;
}
