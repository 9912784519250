import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Input from '../form/Input';
import Button from '../form/Button';
import Spinner from '../spinner/Spinner';

const randomStringMake = (count) => {
	const letter = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let randomString = '';
	for (let i = 0; i < count; i++) {
		const randomStringNumber = Math.floor(1 + Math.random() * (letter.length - 1));
		randomString += letter.substring(randomStringNumber, randomStringNumber + 1);
	}
	return randomString;
};

export default function AddUser() {
	const { auth } = useContext(AuthContext);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const tmpPwrd = randomStringMake(10);
			console.log('tmpPwrd:', tmpPwrd);
			const { data } = await axios.post(`/addUser/${auth.user.admin}`, {
				fname,
				lname,
				email,
				password: tmpPwrd,
			});
			console.log('data:', data);
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				//email user with info
				const toSend = {
					fname: fname,
					password: tmpPwrd,
					toEmail: email,
				};
				emailjs.send('service_gry8cdt', 'template_a4zkoes', toSend, 'Y3PCVvn8qu3sFfnw-');
			}
			setFname('');
			setLname('');
			setEmail('');
			setLoading(false);
			toast.success('User added successfully');
		} catch (error) {
			setLoading(false);
			toast.error(error);
		}
	};

	return (
		<div className='row mt-4'>
			<div className='col-10 col-md-4 offset-1 offset-md-4'>
				<form onSubmit={handleSubmit}>
					<Input label='First Name' type='text' value={fname} setValue={setFname} />
					<Input label='Last Name' type='text' value={lname} setValue={setLname} />
					<Input label='Email' type='email' value={email} setValue={setEmail} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' disabled={!fname || !lname || !email}>
							Save User
						</Button>
					</div>
				</form>
			</div>
			{loading && <Spinner />}
		</div>
	);
}
