import React, { useCallback, useContext, useEffect, useState } from 'react';
import './client.css';
import { Link } from 'react-router-dom';
import { encode, decode } from 'string-encode-decode';
import { AuthContext } from '../../../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import icoAdd from '../../../assets/images/icoAdd.png';
import delCard from '../../../assets/images/icoDel.png';
import Input from '../../../components/form/Input';
import Button from '../../../components/form/Button';
import CheckBoxDomain from '../../../components/form/CheckBoxDomain';
import Spinner from '../../../components/spinner/Spinner';

import * as Realm from 'realm-web';
const app = new Realm.App({ id: 'zaxxiss-app-mcqtr' });

const formatNumber = (value) => {
	if (!value) return value;
	let number = value.replace(/[^\d]/g, '');
	const numLength = number.length;

	if (numLength < 5) return number;

	if (numLength >= 5 && numLength < 9) {
		return `${number.slice(0, 4)} ${number.slice(4)}`;
	}

	if (numLength >= 9 && numLength < 13) {
		return `${number.slice(0, 4)} ${number.slice(4, 8)} ${number.slice(8)}`;
	}

	if (numLength >= 13 && numLength < 17) {
		return `${number.slice(0, 4)} ${number.slice(4, 8)} ${number.slice(8, 12)} ${number.slice(12)}`;
	}
};

const formatExpiration = (value) => {
	if (!value) return value;
	let expiration = value.replace(/[^\d]/g, '');
	const expLength = expiration.length;

	if (expLength < 3) return expiration;

	if (expLength >= 3) {
		return `${expiration.slice(0, 2)}/${expiration.slice(2)}`;
	}
};

export default function Client() {
	let chkdDomains = [];
	const dbName = process.env.REACT_APP_DB_NAME;
	const { auth } = useContext(AuthContext);
	const [payMethods, setPayMethods] = useState([]);
	const [allDomains, setAllDomains] = useState([]);
	const [nonPayDomains, setNonPayDomains] = useState([]);
	const [num, setNum] = useState('');
	const [exp, setExp] = useState('');
	const [cvv, setCvv] = useState('');
	const [street, setStreet] = useState('');
	const [shwAddCard, setShwAddCard] = useState(false);
	const [loading, setLoading] = useState(false);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// LOAD DATA FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadPayInfos = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getPayinfos/${auth.user._id}`);
			setPayMethods(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);

	const loadAllDomains = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getAllDomains/${auth.user._id}`);
			setAllDomains(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);

	const loadNonPayDomains = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getNonPayDomains/${auth.user._id}`);
			setNonPayDomains(data);
		} catch (err) {
			toast.error(err);
		}
	}, [auth]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CALL LOAD DATA FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		loadPayInfos();
	}, [loadPayInfos]);

	useEffect(() => {
		loadAllDomains();
	}, [loadAllDomains]);

	useEffect(() => {
		loadNonPayDomains();
	}, [loadNonPayDomains]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CHANGE STREAM WATCHES
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const payMethods = async () => {
			await app.logIn(Realm.Credentials.anonymous());

			// Connect to the database
			const mongodb = app.currentUser.mongoClient('mongodb-atlas');
			const pay = mongodb.db(dbName).collection('payinfos');

			for await (const change of pay.watch()) {
				if (change.operationType === 'insert' || change.operationType === 'update' || change.operationType === 'delete') {
					loadPayInfos();
					loadAllDomains();
					loadNonPayDomains();
				}
			}
		};
		payMethods();
	}, [dbName, loadPayInfos, loadAllDomains, loadNonPayDomains]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// SUBMIT FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const last4 = num.slice(-4);
		const encNum = encode(num);
		const encLast4 = encode(last4);
		const encExp = encode(exp);
		const encCode = encode(cvv);
		const encStreet = encode(street);
		try {
			if (chkdDomains.length === 0) {
				setLoading(false);
				toast.error('Please choose at least one domain for this card');
				return;
			} else {
				const { data } = await axios.post(`/addPayMethod`, {
					number: encNum,
					last4: encLast4,
					expiration: encExp,
					code: encCode,
					street: encStreet,
					userObjId: auth.user._id,
				});

				if (data.length === 0) {
					setLoading(false);
					toast.error('Server Error: Please try again');
				} else {
					const payId = data._id;
					nonPayDomains.forEach(async (dmn) => {
						if (chkdDomains.includes(dmn._id)) {
							await axios.put(`/updDmnPayMethod/${dmn._id}`, {
								payinfoObjId: payId,
							});
						}
					});
					//email me that user has updated pay method
					const toSend = {
						fname: auth.user.fname,
						lname: auth.user.lname,
					};
					emailjs.send('service_gry8cdt', 'template_nw5gg65', toSend, 'Y3PCVvn8qu3sFfnw-');
					loadPayInfos();
					loadAllDomains();
					loadNonPayDomains();
					setLoading(false);
				}
				setNum('');
				setExp('');
				setCvv('');
				setStreet('');
				chkdDomains = [];
				toast.success('Payment method saved successfully');
				handleShows('closeCard');
			}
		} catch (error) {
			setLoading(false);
			toast.error('Stripe Error: Please try again.');
		}
	};

	const handleDelCard = async (payId) => {
		const { data } = await axios.delete(`/delCard/${payId}`);
		if (data.ok) {
			//email me that user has deleted pay method
			const toSend = {
				fname: auth.user.fname,
				lname: auth.user.lname,
			};
			emailjs.send('service_gry8cdt', 'template_jaldvky', toSend, 'Y3PCVvn8qu3sFfnw-');
			loadNonPayDomains();
			handleShows('addCard');
			toast.success('Payment method deleted successfully');
		} else {
			toast.error('Stripe Error: Please try again');
		}
	};

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// FORM FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	function handleNumber(e) {
		const value = e.target.value;
		let formattedNum = formatNumber(value);
		setNum(formattedNum);
	}

	function handleExp(e) {
		const value = e.target.value;
		let formattedExp = formatExpiration(value);
		setExp(formattedExp);
	}

	function handleChkDomain(e, dmnId) {
		const value = e.target.checked;
		if (value) {
			chkdDomains.push(dmnId);
		} else {
			if (chkdDomains.includes(dmnId)) {
				chkdDomains.splice(chkdDomains.indexOf(dmnId), 1);
			}
		}
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// HANDLE SHOW FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const handleShows = (show) => {
		if (show === 'addCard') {
			shwAddCard ? setShwAddCard(false) : setShwAddCard(true);
		}
		if (show === 'closeCard') {
			setShwAddCard(false);
		}
	};

	function handlePayDomains(payId) {
		document.getElementById('allDmns').style.display = 'none';
		payMethods.forEach((pay) => {
			if (pay._id === payId) {
				document.getElementById(pay._id).style.display = 'block';
			} else {
				document.getElementById(pay._id).style.display = 'none';
			}
		});
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className='row mt-4'>
				<div className='col-12 col-md-4'>
					{payMethods.length >= 1 && (
						<>
							{payMethods.map((pay) => (
								<div className='row mb-2 d-flex align-items-center' key={pay._id}>
									<div className='col-6 d-flex justify-content-end'>
										<div className='dmnLink' onClick={(e) => handlePayDomains(pay._id)} style={{ marginTop: '5px' }}>
											{decode(pay.last4)}
										</div>
									</div>
									<div className='col-6 ps-3'>
										<img className='icon' src={delCard} alt='Delete' title='Delete Payment Method' onClick={() => handleDelCard(pay._id)} />
									</div>
								</div>
							))}
						</>
					)}
					{payMethods.length === 0 && (
						<div className='row mb-2 d-flex align-items-center'>
							<div className='col-12 d-flex justify-content-center'>
								<div className='errMsg'>No Payment Methods Found</div>
							</div>
						</div>
					)}
					<div className='row mb-3 d-flex align-items-center'>
						<div className='col-12 d-flex justify-content-center'>
							<img className='icon' src={icoAdd} alt='Add Card' title='Add Payment Method' onClick={() => handleShows('addCard')} />
						</div>
					</div>
					{shwAddCard && (
						<div className='row mb-3 d-flex align-items-center'>
							<div className='col-12 d-flex justify-content-center'>
								<div className='cardContainer p-3'>
									<Input label='Card Number' type='text' max='19' value={num} funcCall={handleNumber} />
									<div className='row'>
										<div className='col-6'>
											<Input label='Expiration' type='text' max='5' value={exp} funcCall={handleExp} />
										</div>
										<div className='col-6'>
											<Input label='CVV' type='text' max='4' value={cvv} setValue={setCvv} />
										</div>
									</div>
									<Input label='Street Address' type='text' value={street} setValue={setStreet} />
								</div>
							</div>
						</div>
					)}
				</div>
				{shwAddCard && (
					<>
						<div className='col-12 col-md-4'>
							<div className='row'>
								<div className='col-12'>
									<div className='infoText'>
										<p>
											Please choose which domains to use this card for. This card will be used for all auto-pay payments (monthly hosting, annual domain renewals,
											annual email services), and any one-off invoices for services pertaining to any of these domains. **PLEASE NOTE: One-off invoices will be paid
											automatically 3 days after sending out invoice, if not already paid by then.
										</p>
										<div>Couple other important notes:</div>
										<ul>
											<li className='mt-2 mb-1'>Monthly hosting payments will return to the 15th of each month for everyone</li>
											<li className='my-1'>
												Annual domain renewals will only be done twice a year, on Jan. 1st and July 1st. So any domains that expire between those 2 dates will be
												renewed automatically on those days, unless you give me plenty of time to stop the auto-pay. Once domains are renewed, there are no refunds.
											</li>
											<li className='mt-1 mb-2'>
												Annual email service renewals will be auto-charged 1 month before the expiration to make sure there are no issues with payment and no
												interuption to email service.
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-12'>
									<div className='mt-4 d-flex justify-content-center'>
										<Button type='submit' disabled={!num || !exp || !cvv || !street}>
											Save Pay Method
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className='col-12 col-md-4'>
							<div className='cardContainer p-3'>
								{nonPayDomains.map((dmn) => (
									<div className='row mb-2 d-flex align-items-center' key={dmn._id}>
										<div className='col-1 p-0 d-flex justify-content-center'>
											<CheckBoxDomain funcCall={handleChkDomain} payId={dmn.payinfoObjId} dmnId={dmn._id} />
										</div>
										<div className='col-10 p-0'>
											<Link className='dmnLink' to={`/viewdomain/${dmn._id}`}>
												{dmn.name}
											</Link>
										</div>
									</div>
								))}
							</div>
						</div>
					</>
				)}
				{!shwAddCard && (
					<div className='col-12 col-md-8'>
						<div className='row mb-3'>
							<div className='col-1'></div>
							<div className='col-3 p-0'>
								<div className='hdngText'>Domain</div>
							</div>
							<div className='col-1 p-0 d-flex justify-content-center'>
								<div className='hdngText'>Expire</div>
							</div>
							<div className='col-1 p-0 d-flex justify-content-center'>
								<div className='hdngText'>Hosted</div>
							</div>
							<div className='col-2 p-0 d-flex justify-content-center'>
								<div className='hdngText'>Hosting</div>
							</div>
							<div className='col-2 p-0 d-flex justify-content-center'>
								<div className='hdngText'>Renewal</div>
							</div>
							<div className='col-2 p-0 d-flex justify-content-center'>
								<div className='hdngText'>Email</div>
							</div>
						</div>
						<div className='p-0' id='allDmns'>
							{allDomains.map((dmn) => (
								<div className='row mb-2 d-flex align-items-center' key={dmn._id}>
									<div className='col-1 pe-3 d-flex justify-content-end'>
										<input className='dmnChkBox' type='checkbox' readOnly={true} />
									</div>
									<div className='col-3 p-0'>
										<div className='dmnInfoText'>{dmn.name}</div>
									</div>
									<div className='col-1 p-0'>
										<div className='dmnInfoText'>{dmn.expire}</div>
									</div>
									<div className='col-1 p-0 d-flex justify-content-center'>
										{dmn.hosted && <input className='dmnChkBox' type='checkbox' checked readOnly={true} />}
										{!dmn.hosted && <input className='dmnChkBox' type='checkbox' readOnly={true} />}
									</div>
									<div className='col-2 p-0 d-flex justify-content-center'>
										<div className='dmnInfoText'>${(dmn.hostingprice / 100).toFixed(2)}</div>
									</div>
									<div className='col-2 p-0 d-flex justify-content-center'>
										<div className='dmnInfoText'>${(dmn.renewalprice / 100).toFixed(2)}</div>
									</div>
									<div className='col-2 p-0 d-flex justify-content-center'>
										<div className='dmnInfoText'>${(dmn.emailprice / 100).toFixed(2)}</div>
									</div>
								</div>
							))}
						</div>
						{payMethods.map((pay) => (
							<div className='p-0' key={pay._id} id={pay._id} style={{ display: 'none' }}>
								{allDomains.map((dmn) => (
									<div className='row mb-2 d-flex align-items-center' key={dmn._id}>
										<div className='col-1 pe-3 d-flex justify-content-end'>
											{dmn.payinfoObjId === pay._id ? (
												<input className='dmnChkBox' type='checkbox' readOnly={true} checked />
											) : (
												<input className='dmnChkBox' type='checkbox' readOnly={true} />
											)}
										</div>
										<div className='col-3 p-0'>
											<div className='dmnInfoText'>{dmn.name}</div>
										</div>
										<div className='col-1 p-0'>
											<div className='dmnInfoText'>{dmn.expire}</div>
										</div>
										<div className='col-1 p-0 d-flex justify-content-center'>
											{dmn.hosted && <input className='dmnChkBox' type='checkbox' checked readOnly={true} />}
											{!dmn.hosted && <input className='dmnChkBox' type='checkbox' readOnly={true} />}
										</div>
										<div className='col-2 p-0 d-flex justify-content-center'>
											<div className='dmnInfoText'>${(dmn.hostingprice / 100).toFixed(2)}</div>
										</div>
										<div className='col-2 p-0 d-flex justify-content-center'>
											<div className='dmnInfoText'>${(dmn.renewalprice / 100).toFixed(2)}</div>
										</div>
										<div className='col-2 p-0 d-flex justify-content-center'>
											<div className='dmnInfoText'>${(dmn.emailprice / 100).toFixed(2)}</div>
										</div>
									</div>
								))}
							</div>
						))}
					</div>
				)}
			</div>
			{loading && <Spinner />}
		</form>
	);
}
