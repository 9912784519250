import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './context/auth';
import PrivateRoutes from './components/routes/PrivateRoutes';
import Background from './components/backgrounds/Background';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/public/home/Home';
import Mobile from './pages/public/mobile/Mobile';
import Web from './pages/public/web/Web';
import Software from './pages/public/software/Software';
import Contact from './pages/public/contact/Contact';
import Login from './pages/public/login/Login';
import ForgotPassword from './pages/public/login/ForgotPassword';
import PageNotFound from './pages/public/PageNotFound';
import Client from './pages/private/clients/Client';
import Admin from './pages/private/admin/Admin';
import EditDomain from './components/editdomain/EditDomain';

function App() {
	const [curWidth, setCurWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setCurWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);
	}, []);

	return (
		<AuthProvider>
			<BrowserRouter>
				<Background>
					<Header width={curWidth} />
					<Toaster
						toastOptions={{
							success: {
								duration: 5000,
								theme: {
									primary: 'green',
									secondary: 'green',
								},
								style: {
									border: '2px solid #65fe08',
								},
							},
							error: {
								duration: 5000,
								theme: {
									primary: '#ec2e38',
									secondary: '#ec2e38',
								},
								style: {
									border: '2px solid #ec2e38',
								},
							},
						}}
					/>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/mobile' element={<Mobile />} />
						<Route path='/web' element={<Web />} />
						<Route path='/software' element={<Software />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='/login' element={<Login />} />
						<Route path='/forgotpassword' element={<ForgotPassword />} />
						<Route path='/client' element={<PrivateRoutes />}>
							<Route path='' element={<Client />} />
						</Route>
						<Route path='/admin' element={<PrivateRoutes />}>
							<Route path='' element={<Admin />} />
						</Route>
						<Route path='/editdomain' element={<PrivateRoutes />}>
							<Route path=':dmnId' element={<EditDomain />} />
						</Route>
						<Route path='*' element={<PageNotFound />} />
					</Routes>
					<Footer />
				</Background>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
