import React, { useState } from 'react';
import AddUser from '../../../components/adduser/AddUser';
import AdminUser from '../../../components/adminuser/AdminUser';
import AddDomain from '../../../components/adddomain/AddDomain';
import ViewCards from '../../../components/view/ViewCards';
import ViewDomains from '../../../components/viewdomains/ViewDomains';

export default function Admin() {
	const [shwAddUser, setShwAddUser] = useState(false);
	const [shwAdmUser, setShwAdmUser] = useState(false);
	const [shwAddDmn, setShwAddDmn] = useState(false);
	const [shwView, setShwView] = useState(false);
	const [shwVwDmns, setShwVwDmns] = useState(false);

	const handleShows = (show) => {
		if (show === 'addUser') {
			shwAddUser ? setShwAddUser(false) : setShwAddUser(true);
			setShwAdmUser(false);
			setShwAddDmn(false);
			setShwView(false);
			setShwVwDmns(false);
		}
		if (show === 'admUser') {
			setShwAddUser(false);
			shwAdmUser ? setShwAdmUser(false) : setShwAdmUser(true);
			setShwAddDmn(false);
			setShwView(false);
			setShwVwDmns(false);
		}
		if (show === 'addDmn') {
			setShwAddUser(false);
			setShwAdmUser(false);
			shwAddDmn ? setShwAddDmn(false) : setShwAddDmn(true);
			setShwView(false);
			setShwVwDmns(false);
		}
		if (show === 'vwCard') {
			setShwAddUser(false);
			setShwAdmUser(false);
			setShwAddDmn(false);
			shwView ? setShwView(false) : setShwView(true);
			setShwVwDmns(false);
		}
		if (show === 'vwDmns') {
			setShwAddUser(false);
			setShwAdmUser(false);
			setShwAddDmn(false);
			setShwView(false);
			shwVwDmns ? setShwVwDmns(false) : setShwVwDmns(true);
		}
	};

	return (
		<>
			<div className='mt-5 d-flex flex-row justify-content-center'>
				<div className='col-1'></div>
				<div className='col-12 col-sm-2 d-flex justify-content-center '>
					<div className='txtLink' onClick={() => handleShows('addUser')}>
						Add User
					</div>
				</div>
				<div className='col-12 col-sm-2 d-flex justify-content-center '>
					<div className='txtLink' onClick={() => handleShows('admUser')}>
						Admin User
					</div>
				</div>
				<div className='col-12 col-sm-2 d-flex justify-content-center '>
					<div className='txtLink' onClick={() => handleShows('addDmn')}>
						Add Domain
					</div>
				</div>
				<div className='col-12 col-sm-2 d-flex justify-content-center '>
					<div className='txtLink' onClick={() => handleShows('vwCard')}>
						View Cards
					</div>
				</div>
				<div className='col-12 col-sm-2 d-flex justify-content-center '>
					<div className='txtLink' onClick={() => handleShows('vwDmns')}>
						View Domains
					</div>
				</div>
				<div className='col-1'></div>
			</div>
			{shwAddUser && <AddUser />}
			{shwAdmUser && <AdminUser />}
			{shwAddDmn && <AddDomain />}
			{shwView && <ViewCards />}
			{shwVwDmns && <ViewDomains />}
		</>
	);
}
