import React from 'react';
import img from '../../../assets/images/pgWeb.png';

export default function Web() {
	return (
		<div className='mt-3 d-flex flex-row justify-content-around'>
			<div className='col-10 col-md-5 py-2 d-flex align-items-center justify-content-center'>
				<img className='pgImg' src={img} alt='Web-Based Apps' />
			</div>
			<div className='col-10 col-md-5 py-2'>
				<div className='pgText'>
					<p>
						Zaxxiss is different than most digital agencies. Our roots run deep in developing and programming custom web solutions. From API&apos;s, Software Integration, and
						Custom Web Applications, we have the technical aptitude, experience, and skills to create solutions that help you run your business, save time working online,
						increase efficiencies, and maximize your profits.
					</p>
					<p>
						While websites are often informational, many businesses need a web solution that allows them to perform actions or tasks. That is the difference between a website and
						a web application. The two are not exclusive and we believe that they don&apos;t have to be or should be. Doing business today requires your company, staff, and team
						to constantly manage their time multi-tasking through various online tools, different software, dashboards, and reporting. At Zaxxiss our developers work to create a
						business solution that gives you the opportunity to be more effective online. Saving you time with a safe and secure portal to your web app, designed specifically for
						your business and needs.
					</p>
					<p>
						Zaxxiss can also host your site with our world-class cloud hosting solutions to ensure maximum up time and a worry-free, single point of contact, to troubleshoot and
						fix any issues if they do arise.
					</p>
					<p>
						The possibilities are endless, and some common types of web applications that we&apos;ve designed, developed, and implemented are:
						<ul>
							<li>Content Management Systems (CMS)</li>
							<li>Customer Relationship Management (CRM)</li>
							<li>Business Process Automation Systems</li>
							<li>E-Commerce & Payment Gateways</li>
							<li>Shipping & Distribution Systems</li>
							<li>Company Facing & Customer Facing Web Portals</li>
							<li>Paperless Office Solutions</li>
							<li>Membership Platforms</li>
							<li>and Many More...</li>
						</ul>
					</p>
				</div>
			</div>
		</div>
	);
}
