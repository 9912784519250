'use client';
import React, { useEffect, useState } from 'react';
import './background.css';

export default function Background({ children }) {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);
	}, []);

	return (
		<>
			{width >= 1201 && <div className='bgLarge'>{children}</div>}
			{width <= 1200 && width >= 771 && <div className='bgMedium'>{children}</div>}
			{width <= 770 && <div className='bgSmall'>{children}</div>}
		</>
	);
}
