import React from 'react';
import './Form.css';

export default function BotTest({ label, type, name, id, value, setValue }) {
	return (
		<div className='testinput mb-3'>
			<label className='frmLabel'>{label}</label>
			<input className='form-control inpStyle' type={type} name={name} id={id} value={value} onChange={(e) => setValue(e.target.value)} />
		</div>
	);
}
