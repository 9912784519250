import React from 'react';
import img from '../../../assets/images/pgMobile.png';

export default function Mobile() {
	return (
		<div className='mt-3 d-flex flex-row justify-content-around'>
			<div className='col-10 col-md-5 py-2 d-flex align-items-center justify-content-center'>
				<img className='pgImg' src={img} alt='Mobile Apps' />
			</div>
			<div className='col-10 col-md-5 py-2'>
				<div className='pgText'>
					<p>
						If you are reading this, it can be safely assumed that you are looking to have a mobile application for your business or have a business built upon it. Since mobile
						apps these days carry with them immense opportunities, when you think about mobile apps, you need to consider all dimensions related to them - the market for mobile
						apps, the cost of development, and the expertise of mobile app development.
					</p>
					<p>
						The cost of building a mobile app can be fairly great, as far as money goes, but we would also like to highlight another cost - the time and commitment. It takes
						around 1-3 months to build an average mobile app, which is a long time for ideas to evolve and markets to mature. The point being, when you hire a mobile app
						development company, ensure that you also take into account such considerations along with the billing amount.
					</p>
					<p>
						Zaxxiss is a team of digital experts that thrive on helping all businesses build applications that matter, from the small start-ups to the most admired companies in
						the world.
					</p>
					<p>
						Be it an innovative consumer app or a next-gen enterprise system - we do it all, from product idea and concept development to system roll-out and scale-up. We
						envision, prototype, create, and test products and solutions to find new competitive advantages for your business.
					</p>
					<p>
						There are many development companies that you can choose from out there, but we know that our job is to do 4 simple things:
						<ul>
							<li>Develop your mobile application</li>
							<li>Maximize its value in the marketplace</li>
							<li>Make it easy to use for you and your customers</li>
							<li>Reduce or mitigate any risk throughout the process</li>
						</ul>
					</p>
				</div>
			</div>
		</div>
	);
}
