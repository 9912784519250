import React, { useCallback, useContext, useEffect, useState } from 'react';
import './editdomain.css';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Input from '../form/Input';
import Button from '../form/Button';
import Spinner from '../spinner/Spinner';
import Checkbox from '../form/Checkbox';

const formatPrice = (value) => {
	if (!value) return value;
	let tmpPrice = value.replace(/[^\d]/g, '');
	const tmpPriceLength = tmpPrice.length;

	if (tmpPriceLength < 3) return tmpPrice;

	if (tmpPriceLength >= 3) {
		let convertPrice = tmpPrice.toString();
		let cents = convertPrice.slice(-2);
		let dollars = convertPrice.slice(0, -2);
		return `${dollars}.${cents}`;
	}
};

export default function EditDomain() {
	const navigate = useNavigate();
	const { dmnId } = useParams();
	const { auth } = useContext(AuthContext);
	const [domain, setDomain] = useState({});
	const [name, setName] = useState('');
	const [exp, setExp] = useState('');
	const [hosted, setHosted] = useState(false);
	const [hostPrice, setHostPrice] = useState('');
	const [renewPrice, setRenewPrice] = useState('');
	const [emailPrice, setEmailPrice] = useState('');
	const [loading, setLoading] = useState(false);

	const loadDomain = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getDomain/${dmnId}/${auth.user.admin}`);
			if (Object.keys(data).length === 0) {
				toast.error('Domain not found');
				return;
			} else {
				setDomain(data);
			}
		} catch (err) {
			toast.error(err);
		}
	}, [dmnId, auth]);

	useEffect(() => {
		loadDomain();
	}, [loadDomain]);

	useEffect(() => {
		if (Object.keys(domain) !== 0 && (name === '' || name === undefined)) {
			setName(domain.name);
			setExp(domain.expire);
			setHosted(domain.hosted);
			setHostPrice((domain.hostingprice / 100).toString());
			setRenewPrice((domain.renewalprice / 100).toString());
			setEmailPrice((domain.emailprice / 100).toString());
		}
	}, [domain, name]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const convertedHostPrice = parseInt(hostPrice * 100);
			const convertedRenewPrice = parseInt(renewPrice * 100);
			const convertedEmailPrice = parseInt(emailPrice * 100);
			await axios.put(`/updDomain/${dmnId}/${auth.user.admin}`, {
				name,
				expire: exp,
				hosted,
				hostingprice: convertedHostPrice,
				renewalprice: convertedRenewPrice,
				emailprice: convertedEmailPrice,
			});
			setName('');
			setExp('');
			setHosted(false);
			setHostPrice('');
			setRenewPrice('');
			setEmailPrice('');
			setLoading(false);
			toast.success('Domain updated successfully');
			navigate('/admin');
		} catch (error) {
			setLoading(false);
			toast.error(error);
		}
	};

	const handleHosted = (e) => {
		const value = e.target.checked;
		setHosted(value);
	};

	function handleHostPrice(e) {
		const value = e.target.value;
		const formattedPrice = formatPrice(value);
		setHostPrice(formattedPrice);
	}

	function handleRenewPrice(e) {
		const value = e.target.value;
		const formattedPrice = formatPrice(value);
		setRenewPrice(formattedPrice);
	}

	function handleEmailPrice(e) {
		const value = e.target.value;
		const formattedPrice = formatPrice(value);
		setEmailPrice(formattedPrice);
	}

	return (
		<div className='row mt-4'>
			<div className='col-10 col-md-4 offset-1 offset-md-4'>
				<div className='mb-3 d-flex justify-content-center'>
					<h2 className='pgHdng'>Edit Domain</h2>
				</div>
				<form onSubmit={handleSubmit}>
					<Input label='Domain Name' type='text' value={name} setValue={setName} />
					<Input label='Expire' type='text' value={exp} setValue={setExp} />
					<div className='row mb-3'>
						<div className='col-1 pe-2'>
							<Checkbox checked={hosted} funcCall={handleHosted} />
						</div>
						<div className='col-10 ps-2'>
							<div className='frmLabel'>Hosted</div>
						</div>
					</div>
					<Input label='Hosting Price' type='text' value={hostPrice} funcCall={handleHostPrice} />
					<Input label='Renewal Price' type='text' value={renewPrice} funcCall={handleRenewPrice} />
					<Input label='Email Price' type='text' value={emailPrice} funcCall={handleEmailPrice} />
					<div className='mt-4 d-flex justify-content-center'>
						<Button type='submit' disabled={!name || !renewPrice}>
							Save Domain
						</Button>
					</div>
				</form>
			</div>
			{loading && <Spinner />}
		</div>
	);
}
