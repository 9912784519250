import React, { useCallback, useContext, useEffect, useState } from 'react';
import './viewdomains.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Select from 'react-select';

export default function ViewDomains() {
	const { auth } = useContext(AuthContext);
	const [usersOptions] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [user, setUser] = useState({});
	const [allDomains, setAllDomains] = useState([]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// LOAD DATA FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadUsers = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getUsers/${auth.user._id}`);
			if (data.length === 0) {
				toast.error('No users found');
				return;
			} else {
				if (usersOptions.length === 0) {
					data.forEach((user) => usersOptions.push({ label: `${user.fname} ${user.lname}`, value: user._id }));
				}
			}
		} catch (err) {
			toast.error(err);
		}
	}, [auth, usersOptions]);
	const loadUser = useCallback(
		async (userId) => {
			try {
				const { data } = await axios.get(`/getUser/${userId}/${auth.user.admin}`);
				if (Object.keys(data).length === 0) {
					toast.error('User not found');
					return;
				} else {
					setUser(data);
				}
			} catch (err) {
				toast.error(err);
			}
		},
		[auth]
	);
	const loadAllDomains = useCallback(async (userId) => {
		try {
			const { data } = await axios.get(`/getAllDomains/${userId}`);
			setAllDomains(data);
		} catch (err) {
			toast.error(err);
		}
	}, []);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CALL LOAD DATA FUNCTIONS
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		loadUsers();
	}, [loadUsers]);

	useEffect(() => {
		if (Object.keys(selectedUser).length !== 0 && Object.keys(user).length === 0) {
			loadUser(selectedUser.value);
		}
	}, [selectedUser, user, loadUser]);

	useEffect(() => {
		if (Object.keys(selectedUser).length !== 0 && Object.keys(user).length === 0) {
			loadAllDomains(selectedUser.value);
		}
	}, [selectedUser, user, loadAllDomains]);

	return (
		<>
			<div className='row mt-4'>
				<div className='col-4 offset-4'>
					<div className='mb-3'>
						<Select
							isMulti={false}
							blurInputOnSelect={true}
							closeMenuOnSelect={true}
							options={usersOptions}
							onChange={setSelectedUser}
							styles={{
								control: (baseStyles) => ({
									...baseStyles,
									backgroundColor: '#c9c9c9',
									border: '2px solid black',
									borderRadius: '7px',
								}),
							}}
						/>
					</div>
				</div>
			</div>
			{Object.keys(user).length >= 1 && (
				<div className='row mt-4'>
					<div className='col-10 offset-1'>
						<div className='row mb-3 d-flex align-items-center'>
							<div className='col-1'></div>
							<div className='col-3'>
								<div className='hdngText'>Domain</div>
							</div>
							<div className='col-1 d-flex justify-content-center'>
								<div className='hdngText'>Expire</div>
							</div>
							<div className='col-1 d-flex justify-content-center'>
								<div className='hdngText'>Hosted</div>
							</div>
							<div className='col-2 d-flex justify-content-center'>
								<div className='hdngText'>Hosting</div>
							</div>
							<div className='col-2 d-flex justify-content-center'>
								<div className='hdngText'>Renewal</div>
							</div>
							<div className='col-2 d-flex justify-content-center'>
								<div className='hdngText'>Email</div>
							</div>
						</div>
						{allDomains.map((dmn) => (
							<div className='row mb-2 d-flex align-items-center' key={dmn._id}>
								<div className='col-1'></div>
								<div className='col-3'>
									<Link className='cardLink' to={`/editdomain/${dmn._id}`}>
										{dmn.name}
									</Link>
								</div>
								<div className='col-1 d-flex justify-content-center'>
									<div className='cardText'>{dmn.expire}</div>
								</div>
								<div className='col-1 d-flex justify-content-center'>
									{dmn.hosted && <input className='dmnChkBox' type='checkbox' checked readOnly={true} />}
									{!dmn.hosted && <input className='dmnChkBox' type='checkbox' readOnly={true} />}
								</div>
								<div className='col-2 d-flex justify-content-center'>
									<div className='cardText'>${(dmn.hostingprice / 100).toFixed(2)}</div>
								</div>
								<div className='col-2 d-flex justify-content-center'>
									<div className='cardText'>${(dmn.renewalprice / 100).toFixed(2)}</div>
								</div>
								<div className='col-2 d-flex justify-content-center'>
									<div className='cardText'>${(dmn.emailprice / 100).toFixed(2)}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
}
