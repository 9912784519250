import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Input from '../../../components/form/Input';
import Button from '../../../components/form/Button';
import Cancel from '../../../components/form/Cancel';
import Spinner from '../../../components/spinner/Spinner';

export default function ForgotPassword() {
	const navigate = useNavigate();
	const [isValidToken, setIsValidToken] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const [resetcode, setResetCode] = useState('');
	const [visible, setVisible] = useState(false);
	const [cancelRedirect, setCancelRedirect] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (cancelRedirect) {
			navigate('/login');
		}
	}, [cancelRedirect, navigate]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const { data } = await axios.post('/forgot-password', {
				email,
			});
			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				let toSend = {
					fname: data.user.fname,
					resetcode: data.user.resetcode,
					toEmail: data.user.email,
				};

				if (toSend.fname !== '' && toSend.resetcode !== '' && toSend.toEmail !== '') {
					emailjs.send('service_gry8cdt', 'template_num5xvv', toSend, 'Y3PCVvn8qu3sFfnw-').then((result) => {
						if (result.text === 'OK') {
							setVisible(true);
							toast.success('Please check your email and enter the reset code you received');
						} else {
							toast.error('Email did not send, please try again.');
						}
					});
				} else {
					toast.error('Email did not send, please try again.');
				}
				setLoading(false);
			}
		} catch (err) {
			toast.error('Reset code failed, please try again.');
			setLoading(false);
		}
	};

	const handleReset = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			if (password !== confirm) {
				toast.warning('Passwords do not match');
				return;
			}

			const { data } = await axios.post('/reset-password', {
				email,
				password,
				resetcode: resetcode,
			});

			if (data.error) {
				toast.error(data.error);
				setLoading(false);
				return;
			} else {
				toast.success('Password updated successfully');
				setLoading(false);
				navigate('/login');
			}
		} catch (err) {
			toast.error('Password did not update, please try again.');
			setLoading(false);
		}
	};

	async function handleReCaptchaVerify(token) {
		if (!token) {
			return;
		}

		token && setIsValidToken(true);
	}

	return (
		<div className='mt-5'>
			<div className='container py-3'>
				<h2 className='pgHdng text-center'>Reset Password</h2>
				<div className='formContainer p-3 mx-auto'>
					<form onSubmit={handleSubmit}>
						<GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
						{isValidToken && (
							<>
								<Input label='Email Address' type='email' name='email' id='email' value={email} setValue={setEmail} />
								{visible && (
									<>
										<Input label='Reset Code' type='text' name='code' id='code' value={resetcode} setValue={setResetCode} />
										<Input label='New Password' type='password' name='password' id='password' value={password} setValue={setPassword} />
										<Input label='Confirm New Password' type='password' name='confirm' id='confirm' value={confirm} setValue={setConfirm} />
										<div className='row d-flex justify-content-center'>
											<Button type='submit' disabled={!email || resetcode.length !== 5 || password.length <= 5 || confirm.length <= 5} onClick={handleReset}>
												Update Password
											</Button>
										</div>
									</>
								)}
								{!visible && (
									<div className='row d-flex justify-content-center'>
										<Button type='submit' disabled={!email} onClick={handleSubmit}>
											Submit Request
										</Button>
									</div>
								)}
								<div className='my-3 d-flex justify-content-center'>
									<Cancel text='Cancel' action={() => setCancelRedirect(true)} />
								</div>
							</>
						)}
					</form>
					{loading && <Spinner />}
				</div>
			</div>
		</div>
	);
}
