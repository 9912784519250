import React from 'react';
import './Form.css';

export default function Button({ type, disabled, onClick, children }) {
	return (
		<>
			<button className='button' type={type} disabled={disabled} onClick={onClick}>
				{children}
			</button>
		</>
	);
}
