import React from 'react';
import './home.css';
import imgMobile from '../../../assets/images/hmpgMobile.jpg';
import imgWeb from '../../../assets/images/hmpgWeb.jpg';
import imgSoftware from '../../../assets/images/hmpgSoftware.jpg';
import Card from '../../../components/card/Card';

export default function Home() {
	return (
		<div className='mt-5 d-flex flex-row justify-content-around'>
			<div className='col-12 col-md-4 d-flex justify-content-center'>
				<Card
					img={imgMobile}
					alt='Mobile Apps'
					title='Mobile Apps'
					content='A mobile app is a software application developed specifically for use on small, wireless computing devices, such as smartphones and tablets, rather than desktop or laptop computers. Mobile apps are designed with consideration for the demands and constraints of the devices and also to take advantage of any specialized capabilities they have, such as camera and location.'
					link='mobile'
				/>
			</div>
			<div className='col-12 col-md-4 d-flex justify-content-center'>
				<Card
					img={imgWeb}
					alt='Web Apps'
					title='Web Apps'
					content='A web application is a computer program that utilizes web browsers and web technology to perform tasks over the Internet. They allow users to interact with the company using online forms, content management systems, shopping carts and more. In addition, the applications allow employees to create documents, share information, collaborate on projects, and work on common documents regardless of location or device.'
					link='web'
				/>
			</div>
			<div className='col-12 col-md-4 d-flex justify-content-center'>
				<Card
					img={imgSoftware}
					alt='Custom Software'
					title='Custom Software'
					content="Custom software is developed for a single customer and will accommodate that customer's particular preferences and expectations, which may not be the case for commercial off-the-shelf software. Companies commonly develop custom software for critical functions, including content management, inventory management, customer management, or otherwise to fill the gaps present in existing software packages, or the existing software is just over-kill or too expensive."
					link='software'
				/>
			</div>
		</div>
	);
}
