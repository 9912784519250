import React, { useEffect, useState } from 'react';
import './Form.css';

export default function CheckBoxDomain({ funcCall, payId, dmnId }) {
	const [hasPayMethod, setHasPayMethod] = useState(false);

	useEffect(() => {
		if (payId) {
			setHasPayMethod(true);
		}
	}, [payId]);

	return (
		<>
			{hasPayMethod && <input className='dmnChkBox' type='checkbox' checked onChange={(e) => funcCall(e, dmnId)} />}
			{!hasPayMethod && <input className='dmnChkBox' type='checkbox' onChange={(e) => funcCall(e, dmnId)} />}
		</>
	);
}
