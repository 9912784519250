import React from 'react';

export default function PageNotFound() {
	return (
		<div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '100vw', height: '70vh' }}>
			<div>
				<h1 className='display-1' style={{ color: '#c9c9c9', fontWeight: 'bold' }}>
					404
				</h1>
			</div>
			<div>
				<h3 style={{ color: '#00ff00', fontWeight: 'bold' }}>Page Not Found</h3>
			</div>
		</div>
	);
}
