import React from 'react';
import './Card.css';
import { Link } from 'react-router-dom';

export default function Card({ img, alt, title, content, link }) {
	return (
		<div className='hmCard card mb-3 mb-md-0' style={{ border: '1px solid black' }}>
			<img className='card-img-top' src={img} alt={alt} style={{ borderBottom: '1px solid black' }} />
			<div className='card-body' style={{ backgroundColor: 'black' }}>
				<h5 className='title card-title'>{title}</h5>
				<p className='content card-text pb-4'>{content}</p>
				<Link className='link' to={`/${link}`}>
					Learn More
				</Link>
			</div>
		</div>
	);
}
