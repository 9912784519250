import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import Select from 'react-select';
import Spinner from '../spinner/Spinner';
import Input from '../form/Input';
import Button from '../form/Button';

export default function AdminUser() {
	const { auth } = useContext(AuthContext);
	const [usersOptions] = useState([]);
	const [user, setUser] = useState({});
	const [selectedUser, setSelectedUser] = useState({});
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const loadUsers = useCallback(async () => {
		try {
			const { data } = await axios.get(`/getUsers/${auth.user.admin}`);
			if (data.length === 0) {
				toast.error('No users found');
				return;
			} else {
				if (usersOptions.length === 0) {
					data.forEach((user) => usersOptions.push({ label: `${user.fname} ${user.lname}`, value: user._id }));
				}
			}
		} catch (err) {
			toast.error(err);
		}
	}, [auth, usersOptions]);

	const loadUser = useCallback(
		async (userId) => {
			try {
				const { data } = await axios.get(`/getUser/${userId}/${auth.user.admin}`);
				if (Object.keys(data).length === 0) {
					toast.error('User not found');
					return;
				} else {
					setUser(data);
				}
			} catch (err) {
				toast.error(err);
			}
		},
		[auth]
	);

	useEffect(() => {
		loadUsers();
	}, [loadUsers]);

	useEffect(() => {
		if (Object.keys(user).length === 0 && Object.keys(selectedUser).length !== 0) {
			loadUser(selectedUser.value);
		}
	}, [user, selectedUser, loadUser]);

	useEffect(() => {
		if (user !== null && user !== undefined) {
			if (user.fname !== '' && user.fname !== undefined) {
				setFname(user.fname);
			}
			setLname(user.lname);
			setEmail(user.email);
		}
	}, [user]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const userId = selectedUser.value;
		try {
			await axios.put(`/updUser/${userId}/${auth.user.admin}`, {
				fname,
				lname,
				email,
			});
			setLoading(false);
			toast.success('User updated successfully');
		} catch (error) {
			setLoading(false);
			toast.error(error);
		}
	};

	const handleDelUser = async () => {
		const userId = selectedUser.value;
		const { data } = await axios.delete(`/delUser/${userId}/${auth.user._id}`);
		if (data.ok) {
			setSelectedUser({});
			toast.success('User deleted successfully');
		} else {
			toast.error('Server Error: Please try again');
			return;
		}
	};

	return (
		<div className='row mt-4'>
			<div className='col-10 col-md-4 offset-1 offset-md-4'>
				<div className='mb-3'>
					<Select
						isMulti={false}
						blurInputOnSelect={true}
						closeMenuOnSelect={true}
						options={usersOptions}
						onChange={setSelectedUser}
						styles={{
							control: (baseStyles) => ({
								...baseStyles,
								backgroundColor: '#c9c9c9',
								border: '2px solid black',
								borderRadius: '7px',
							}),
						}}
					/>
				</div>
				<form onSubmit={handleSubmit}>
					<Input label='First Name' type='text' value={fname} setValue={setFname} />
					<Input label='Last Name' type='text' value={lname} setValue={setLname} />
					<Input label='Email' type='email' value={email} setValue={setEmail} />
					<div className='row mt-4'>
						<div className='col-6 d-flex justify-content-center'>
							<Button type='button' disabled={Object.keys(selectedUser).length === 0} onClick={handleDelUser}>
								Delete User
							</Button>
						</div>
						<div className='col-6 d-flex justify-content-center'>
							<Button type='submit' disabled={!fname || !lname || !email}>
								Save User
							</Button>
						</div>
					</div>
				</form>
			</div>
			{loading && <Spinner />}
		</div>
	);
}
