import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../spinner/Spinner';

export default function LoadingRedirect() {
	const navigate = useNavigate();
	const [count, setCount] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			setCount((curCount) => --curCount);
		}, 1000);

		count === 0 && navigate('/login');
		return () => clearInterval(interval);
	}, [count, navigate]);

	return <Spinner />;
}
